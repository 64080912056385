.foko-node {
  &:not(:last-child) {
    margin-bottom: $gap;
  }

  .tag {
    margin-right: 1rem;
  }

  .table {
    border-collapse: separate;
    border-spacing: 5px;
  }
  .table td, .table th {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-bottom: 0;
    border-right: 0;
    font-size: $size-6;
  }

  .table th {
    color: $primary;
    width: 20%;
  }

  .foko-translation, .foko-reference {
    display: inline-block;
    margin-bottom: 0 !important;

    + .foko-translation::before {
      color: $breadcrumb-item-separator-color;
      margin-left: 1rem;
      margin-right: 1rem;
      content: "\0002f"
    }

    + .foko-reference::before {
      color: $breadcrumb-item-separator-color;
      margin-left: 1rem;
      margin-right: 1rem;
      content: "\0002f"
    }
  }
}