.foko-objects {
  .foko-image-placeholder {
    display: block;
    width: 100%;
    height: 100%;
    background: $grey-lighter;
    border-radius: $radius-rounded
  }


  .foko-full-height {
    height: calc(100vh - #{$navbar-height + 3rem});
  }

  .tabs .tag {
    margin-left: 0.5rem;
  }

  .foko-objects-filter {
    .foko-objects-facet {
      padding: $tile-padding;
    }
    .tag {
      cursor: pointer;
    }
    .field {
      align-items: center;
    }
  }

  .foko-object-list li {
    cursor: pointer;
  }

  .foko-objects-map {
    width: 100%;
    margin-top: -1.5rem;
  }
}
