.foko-map {
  .foko-map-container {
    width: 100%;
    height: 100%;

    .marker-cluster-small, .marker-cluster-small div  {
      color: findColorInvert($grey);
      background-color: rgba($grey, 0.6);
    }

    .marker-cluster-medium, .marker-cluster-medium div  {
      color: $yellow-invert;
      background-color: rgba($yellow, 0.6);
    }

    .marker-cluster-large, .marker-cluster-large div  {
      color: $info-invert;
      background-color: rgba($info, 0.6);
    }

    .marker-cluster div {
      font: $family-sans-serif;
      font-weight: bold;
    }
  }
}
