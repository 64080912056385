/* The ribbons */

$ribbon-width: 100px;
$ribbon-vertical-offset: $ribbon-width / 8;
$ribbon-horizontal-offset: $ribbon-width / 4;

.corner-ribbon {
  width: $ribbon-width;
  position: absolute;
  text-align: center;
  line-height: $ribbon-width / 4;
  transform: rotate(-45deg);
  color: $danger-invert;
  background: $danger;
}

/* Custom styles */

.corner-ribbon.sticky {
  position: fixed;
  z-index: 9000;
}

.corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */

.corner-ribbon.top-left {
  top: $ribbon-vertical-offset;
  left: -$ribbon-horizontal-offset;
  transform: rotate(-45deg);
}

.corner-ribbon.top-right {
  top: $ribbon-vertical-offset;
  right: -$ribbon-horizontal-offset;
  left: auto;
  transform: rotate(45deg);
}

.corner-ribbon.bottom-left {
  bottom: $ribbon-vertical-offset;
  left: -$ribbon-horizontal-offset;
  transform: rotate(45deg);
}

.corner-ribbon.bottom-right {
  bottom: $ribbon-vertical-offset;
  right: -$ribbon-horizontal-offset;
  left: auto;
  transform: rotate(-45deg);
}
