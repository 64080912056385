@import '~bulma/sass/utilities/initial-variables';

$primary: #239a91;
$link: #239a91;
$navbar-fixed-z: 2000;
$tile-padding: 0.75rem;

$modal-z: 2000;
$modal-content-width: calc(100vw - 160px);

@import '~bulma/bulma.sass';

@import '~animate.css/animate.css';

@import '~leaflet/dist/leaflet.css';
@import '~leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

@import '~swiper/css/swiper.css';

@import '~awesomplete/awesomplete.css';

.foko-app .awesomplete {
  > ul {
    z-index: 1500;
    > li  {
      color: $black;
    }
  }
}

.modal-open {
  overflow: hidden
}

.foko-has-margin  {
  margin: $column-gap
}

.foko-full-height {
  height: calc(100vh - #{$navbar-height});
}

.foko-min-tile {
  flex-grow: 0;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
  &.foko-min-tile {
    margin-bottom: 0 !important;
  }
}

.foko-objects, .foko-object {
  &.is-loading {
    pointer-events: none;
    &:after {
      @include loader;
      position: fixed;
      top: calc(50vh - 5em);
      left: calc(50vw - 5em);
      width: 10em;
      height: 10em;
      z-index: 2000;
      border-width: 0.25em;
    }
  }
}

.foko-scroll-to-top {
  position: fixed;
  top: 0;
  right: 0;
}

.foko-cookie-message {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
}
@import './ribbon.scss';

@import './home.scss';

@import './query.scss';
@import './map.scss';
@import './objects.scss';

@import './foko-node.scss';
@import './image-viewer.scss';
@import './object.scss';

@import './data.scss';
