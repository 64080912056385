.foko-image-viewer {
  background: $black;
  position: relative;
  display: flex;

  .foko-image-viewer-canvas {
    display: flex;
    width: 100%;

    .displayregioncontainer .displayregion {
      border-radius: $box-radius;
      border-color: $primary !important;
    }
  }

  .foko-image-details {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .foko-image-controls {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
