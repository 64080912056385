.foko-object {
  header {
    .foko-object-image {
      position: relative;

      .foko-image-viewer {
        width: 100%;
        height: 100%;
        min-height: 50vh;
      }

    }
  }

  .foko-object-manifestations {
    position: relative;

    .foko-object-manifestations-details {
      font-size: $size-6;
      opacity: 0.66;
      position: absolute;
      margin: 0;
      max-width: calc(50vw - #{$tile-padding});
      bottom: $tile-padding;
      left: $tile-padding;
      z-index: 900;
    }

    .foko-object-card {
      position: absolute;
      margin: 0;
      @include touch { width: 50vw; }
      @include desktop { width: 33vw; }
      top: $tile-padding;
      left: $tile-padding;
      z-index: 900;
    }

    .foko-object-manifestations-nav {
      position: absolute;
      bottom: $tile-padding;
      right: $tile-padding;
      z-index: 900;
    }
  }
}
