.foko-object-gallery {
  width: 90vw;
  height: 40vh;
  margin: auto;

  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 35vh;
      height: 35vh;

    }
  }
}

footer .hero.is-light a:not(.button):not(.dropdown-item):not(.tag) {
  color: $link;
}
