.foko-query {
  .foko-facets {
    .foko-word-cloud {
      width: 100%;
      height: 30vh;

      text {
        text-anchor: middle;
        cursor: pointer;
      }
    }
  }
}